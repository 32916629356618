#publisher > div {
  height: 100%;
  width: 100%;
}

#subscriber > div {
  height: 100%;
  width: 100%;
}

#subscriber > div > div {
  height: 100%;
  width: 100%;
}

#subscriber > div > div > div {
  height: 100%;
  width: 100%;
}
